<template>
  <div v-if="$wait.waiting('loading companies')">
    Loading
  </div>
  <v-container v-else class="mx-auto">
    <!-- If there are companues -->
    <div v-if="companies.length > 0">
      <h1
        v-translate
        translate-context="Page title"
      >
        Projects
      </h1>
      <div>
        <v-subheader
          v-translate
          class="pl-0"
        >
          Please choose a project
        </v-subheader>
      </div>

      <v-card>
        <v-card-text class="backgroundAccent lighten-5">
          <v-text-field
            v-model="search"
            prepend-icon="search"
            :label="searchPlaceholder"
            single-line
            hide-details
            outlined
            clearable
            dense
            autofocus
          />

          <div
            class="text-right"
          >
            <v-btn
              text
              small
              color="error"
              @click="onProjectMissing"
            >
              <span
                v-translate
                translate-context="MProjectsListPage project missing button text"
              >
                Project missing?
              </span>
            </v-btn>
          </div>

          <template v-for="(company, i) in companies">
            <v-card class="mb-4 px-4">
              <v-card-title class="font-weight-bold pl-0 d-flex justify-space-between">
                <div>
                  {{ company.name }} 
                </div>

                <v-btn
                  v-if="$currentUser.permissions.includes('projects_management')"
                  outlined
                  small
                  class="mt-2"
                  @click="() => openCreateProjectDialog(company)"
                >
                  <span v-translate>
                    Create new project
                  </span>

                  <v-icon
                    right
                    small
                  >
                    add
                  </v-icon>
              </v-btn>
              </v-card-title>

              <v-data-table
                  v-if="projectsByCompanyId[company.id].length > 0"
                  :loading="$wait.is(['loading projects', 'loading companies'])"
                  :headers="headers"
                  :items="projectsByCompanyId[company.id]"
                  :search="search"
                  sort-by="createdAt"
                  :sort-desc="true"
                  class="transparent"
                >
                <template #item.createdAt="{ item: project }">
                    <span>
                      {{ $dayjs(project.createdAt).format('DD.MM.YYYY') }}
                    </span>
                  </template>

                  <template v-slot:item.actions="{ item: project }">
                    <v-btn
                      small
                      depressed
                      color="secondary"
                      :to="projectLink(project)"
                    >
                      <span v-translate>
                        Open
                      </span>
                    </v-btn>
                  </template>
                </v-data-table>
              <v-card-subtitle
                v-if="projectsByCompanyId[company.id].length == 0"
              >
                Ei projekteja
              </v-card-subtitle>
            </v-card>
          </template>
        </v-card-text>
      </v-card>
    </div>
    <!-- Empty state -->
    <div v-else>
      <m-projects-empty-state/>
    </div>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';

  export default {
    data: () => ({
      search: '',
    }),
    components: {
      MProjectsEmptyState: () => import('@/components/MProjectsEmptyState'),
    },
    computed: {
      ...mapGetters({
        projects: 'projects/projects',
        projectsByCompanyId: 'projects/projectsByCompanyId',
        companies: 'companies/companies',
        isMobile: 'utils/isMobile',
      }),

      searchPlaceholder() {
        return this.$pgettext('Text input placeholder', 'Search for a project...');
      },

      projectLink() {
        return ({ id: projectId }) => {
          if (this.$vuetify.breakpoint.mobile) {
            return {
              name: 'own-todos',
              params: {
                projectId,
              },
            };
          }

          return {
            name: 'project-dashboard',
            params: {
              projectId,
            },
          };
        };
      },

      headers() {
        const headers = [
          {
            text: this.$pgettext('Table header', 'Project'),
            value: 'pid',
            width: '40%',
            cellClass: 'subtitle-1 font-weight-bold'
          },
          {
            text: this.$pgettext('Table header', 'Address'),
            value: 'address',
            width: '40%'
          },
          {
            text: this.$pgettext('Table header', 'Actions'),
            value: 'actions',
            sortable: false,
            width: '20%'
          },
        ];

        return headers;
      },
    },

    methods: {
      ...mapActions({
        openDialog: 'dialog/openDialog',
      }),

      openCreateProjectDialog({ id: companyId }) {
        this.openDialog({
          dialogComponent: 'm-create-project-dialog',
          dialogProps: {
            companyId,
          },
          config: {
            fullscreen: this.isMobile,
          },
        });
      },

      onProjectMissing() {
        this.openDialog({
          dialogComponent: 'm-project-missing-dialog',
          config: {
            fullscreen: this.isMobile,
          },
        });
      },
    },
  };
</script>
